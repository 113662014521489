/* eslint-disable */
import React from "react"

const VideoPage98732 = () => {
  return (
    <div>
      <h1>Animation Preview</h1>
      <video
        src={require("./videos/sap-travel-min.mp4")}
        width="auto"
        height="auto"
        loop
        autoPlay
        controls
        style={{ maxWidth: "80vh", maxHeight: "80vw" }}
      />
    </div>
  )
}

export default VideoPage98732
